/*--- SYNTAX HIGHLIGHTING ---------------------------------------------------*/

div.highlighter-rouge, figure.highlight {
    position: relative;
    margin-bottom: 1.5em;
    color: #d0d0d0;
    //@include font-rem(12);
    font-size: 12px;
    line-height: 1.5;
    border: 5px solid darken($body-color, 5);
    border-radius: 3px;
    background-color: #3f3f3f;

    .highlight {
      padding: 1em;
      margin: 0;

		pre {
			padding: 0em;
		}
	}
}

figure.highlight {
  padding-left: 1em;
	padding-right: 1em;
	padding-top: 0px;
	padding-bottom: 0px;
}

.highlight table {
    font-size: 1em;
    border: 0;

    td {
        padding: 5px;
        border: 0;

        // line numbers
        &.gutter {
        padding-right: 1em;
        color: #ccc;
        }
    }

    pre {
        margin: 0;
    }
}

.highlight pre {
    width: 100%;
}
