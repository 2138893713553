.flex {
  display: flex;
  width: 100%;
  
  &-row {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: top;

    .item {
      padding: 10px;
      flex-grow: 1;
      flex-basis: 50%;
    }
  }
}