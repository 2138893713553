@import "variables";

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

ul.list-inline {
  // Add a bit of spacing if two inline lists are placed directly after another
  & + & {
    margin-top: 10px;
  }

  list-style: none;
  padding: 0;

  // Whitespace is preserved between inline elements and there has never
  // been a proper CSS solution, so... we do what everyone does.
  font-size: 0;

  li {
    font-size: $doc-font-size;
    display: inline;

    &:not(:last-child):not(:first-child)::after {
      margin-left: 4px;
      margin-right: 4px;
      content: "\00b7";
    }

    &:first-child {
      &::after {
        margin-left: 4px;
        margin-right: 4px;
        content: "\203a";
      }
    }
  }
}
