.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;

  &.grid-1-2 {
    grid-template-columns: 2;
    grid-template-rows: 1;
  }
}
